import { OnInit, Directive, inject } from '@angular/core'
import { IconButtonComponent, IconButtonThemeType, IconButtonThemeStyle, IconButtonThemeSize, IconButtonIconSize } from './icon-button.component'


@Directive({
  selector: '[icon-btn-unstyled]',
  standalone: false,
})
export class IconButtonUnstyledDirective implements OnInit {
  private _iconButton = inject(IconButtonComponent)
  ngOnInit(): void {
    this._iconButton.themeType = IconButtonThemeType.Unstyled
  }
}

@Directive({
  selector: '[icon-btn-icon-only]',
  standalone: false,
})
export class IconButtonIconOnlyDirective implements OnInit {
  private _iconButton = inject(IconButtonComponent)
  ngOnInit(): void {
    this._iconButton.themeType = IconButtonThemeType.IconOnly
  }
}

@Directive({
  selector: '[icon-btn-outlined]',
  standalone: false,
})
export class IconButtonOutlinedDirective implements OnInit {
  private _iconButton = inject(IconButtonComponent)
  ngOnInit(): void {
    this._iconButton.themeType = IconButtonThemeType.Outlined
  }
}

@Directive({
  selector: '[icon-btn-primary]',
  standalone: false,
})
export class IconButtonPrimaryDirective implements OnInit {
  private _iconButton = inject(IconButtonComponent)
  ngOnInit(): void {
    this._iconButton.themeStyle = IconButtonThemeStyle.Primary
  }
}

@Directive({
  selector: '[icon-btn-secondary]',
  standalone: false,
})
export class IconButtonSecondaryDirective implements OnInit {
  private _iconButton = inject(IconButtonComponent)
  ngOnInit(): void {
    this._iconButton.themeStyle = IconButtonThemeStyle.Secondary
  }
}

@Directive({
  selector: '[icon-btn-white]',
  standalone: false,
})
export class IconButtonWhiteDirective implements OnInit {
  private _iconButton = inject(IconButtonComponent)
  ngOnInit(): void {
    this._iconButton.themeStyle = IconButtonThemeStyle.White
  }
}

@Directive({
  selector: '[icon-btn-small]',
  standalone: false,
})
export class IconButtonSmallDirective implements OnInit {
  private _iconButton = inject(IconButtonComponent)
  ngOnInit(): void {
    this._iconButton.themeSize = IconButtonThemeSize.Small
  }
}

@Directive({
  selector: '[icon-btn-medium]',
  standalone: false,
})
export class IconButtonMediumDirective implements OnInit {
  private _iconButton = inject(IconButtonComponent)
  ngOnInit(): void {
    this._iconButton.themeSize = IconButtonThemeSize.Medium
  }
}

@Directive({
  selector: '[icon-btn-icon-small]',
  standalone: false,
})
export class IconButtonIconSmallDirective implements OnInit {
  private _iconButton = inject(IconButtonComponent)
  ngOnInit(): void {
    this._iconButton.iconSize = IconButtonIconSize.Small
  }
}

@Directive({
  selector: '[icon-btn-icon-medium]',
  standalone: false,
})
export class IconButtonIconMediumDirective implements OnInit {
  private _iconButton = inject(IconButtonComponent)
  ngOnInit(): void {
    this._iconButton.iconSize = IconButtonIconSize.Medium
  }
}

@Directive({
  selector: '[icon-btn-icon-large]',
  standalone: false,
})
export class IconButtonIconLargeDirective implements OnInit {
  private _iconButton = inject(IconButtonComponent)
  ngOnInit(): void {
    this._iconButton.iconSize = IconButtonIconSize.Large
  }
}
