import { Component, Input, inject } from '@angular/core'
import { Router } from '@angular/router'


// These map to directives, e.g. btn-contained, btn-outlined, btn-primary, btn-small, etc
export enum ButtonThemeType {
  Text = 'text',
  Contained = 'contained',
  Outlined = 'outlined',
  Icon = 'icon',
}
export enum ButtonThemeStyle {
  Primary = 'primary',
  Secondary = 'secondary',
}
export enum ButtonThemeSize {
  XSmall = 'xsmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xlarge',
}


@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: false,
})
export class ButtonComponent {
  private _router = inject(Router)
  readonly ButtonThemeType = ButtonThemeType
  readonly ButtonThemeStyle = ButtonThemeStyle
  readonly ButtonThemeSize = ButtonThemeSize
  @Input() themeType?: ButtonThemeType = ButtonThemeType.Contained
  @Input() themeStyle?: ButtonThemeStyle = ButtonThemeStyle.Primary
  @Input() themeSize?: ButtonThemeSize = ButtonThemeSize.Medium
  @Input() styleOverrides?: any = {}
  @Input() href?: string
  @Input() routerLink?: string | string[]
  @Input() disabled?: boolean = false
  @Input() ariaLabel?: string
  @Input() multiRow?: boolean = false

  onClick() {
    if (this.routerLink) {
      this._router.navigate([this.routerLink])
    } else if (this.href) {
      window.location.href = this.href
    }
  }
}
