import { ILanguage, LanguageCode } from '../interfaces/language.interface'


export const usLanguage: ILanguage = {
  name: 'English (US)',
  adminName: 'English (United\u00a0States)',
  code: LanguageCode.EN_US,
}

export const supportedLanguages: ILanguage[] = [{
  ...usLanguage,
}, {
  name: 'Español (MX)',
  adminName: 'Spanish (Mexico)',
  code: LanguageCode.ES_MX,
}, {
  name: 'Français (CA)',
  adminName: 'French (Canada)',
  code: LanguageCode.FR_CA,
}]
