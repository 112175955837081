import { OnInit, Directive, inject } from '@angular/core'
import { ButtonComponent, ButtonThemeSize, ButtonThemeStyle, ButtonThemeType } from './button.component'

@Directive({
  selector: '[btn-text]',
  standalone: false,
})
export class ButtonTextDirective implements OnInit {
  private _button = inject(ButtonComponent)
  ngOnInit(): void {
    this._button.themeType = ButtonThemeType.Text
  }
}

@Directive({
  selector: '[btn-contained]',
  standalone: false,
})
export class ButtonContainedDirective implements OnInit {
  private _button = inject(ButtonComponent)
  ngOnInit(): void {
    this._button.themeType = ButtonThemeType.Contained
  }
}

@Directive({
  selector: '[btn-outlined]',
  standalone: false,
})
export class ButtonOutlinedDirective implements OnInit {
  private _button = inject(ButtonComponent)
  ngOnInit(): void {
    this._button.themeType = ButtonThemeType.Outlined
  }
}

@Directive({
  selector: '[btn-primary]',
  standalone: false,
})
export class ButtonPrimaryDirective implements OnInit {
  private _button = inject(ButtonComponent)
  ngOnInit(): void {
    this._button.themeStyle = ButtonThemeStyle.Primary
  }
}

@Directive({
  selector: '[btn-secondary]',
  standalone: false,
})
export class ButtonSecondaryDirective implements OnInit {
  private _button = inject(ButtonComponent)
  ngOnInit(): void {
    this._button.themeStyle = ButtonThemeStyle.Secondary
  }
}

@Directive({
  selector: '[btn-xsmall]',
  standalone: false,
})
export class ButtonXSmallDirective implements OnInit {
  private _button = inject(ButtonComponent)
  ngOnInit(): void {
    this._button.themeSize = ButtonThemeSize.XSmall
  }
}

@Directive({
  selector: '[btn-small]',
  standalone: false,
})
export class ButtonSmallDirective implements OnInit {
  private _button = inject(ButtonComponent)
  ngOnInit(): void {
    this._button.themeSize = ButtonThemeSize.Small
  }
}

@Directive({
  selector: '[btn-medium]',
  standalone: false,
})
export class ButtonMediumDirective implements OnInit {
  private _button = inject(ButtonComponent)
  ngOnInit(): void {
    this._button.themeSize = ButtonThemeSize.Medium
  }
}

@Directive({
  selector: '[btn-large]',
  standalone: false,
})
export class ButtonLargeDirective implements OnInit {
  private _button = inject(ButtonComponent)
  ngOnInit(): void {
    this._button.themeSize = ButtonThemeSize.Large
  }
}

@Directive({
  selector: '[btn-xlarge]',
  standalone: false,
})
export class ButtonXLargeDirective implements OnInit {
  private _button = inject(ButtonComponent)
  ngOnInit(): void {
    this._button.themeSize = ButtonThemeSize.XLarge
  }
}
